//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CardSimpleNumber from '@/components/cards/SimpleNumber.vue';
import CardBarCharts from '@/components/cards/BarCharts.vue';
import CardLineCharts from '@/components/cards/LineCharts.vue';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
export default {
  name: 'AppsPeopleCountingDownload',
  components: {
    ConfirmModal: ConfirmModal,
    CardSimpleNumber: CardSimpleNumber,
    CardBarCharts: CardBarCharts,
    CardLineCharts: CardLineCharts
  },
  computed: {
    todayPeopleIncrease: function todayPeopleIncrease() {
      return this.stats.today_people - this.stats.today_people_yesterday;
    },
    avgPeopleIncrease: function avgPeopleIncrease() {
      return this.stats.people_avg - this.stats.people_avg_yesterday;
    }
  },
  data: function data() {
    return {
      data_ready: false,
      loading: null,
      stats: {},
      cycle: null
    };
  },
  mounted: function mounted() {
    this.readData();
  },
  methods: {
    readData: function readData() {
      var data = this.$route.query.p;
      var data_decoded = decodeURIComponent(escape(window.atob(data)));
      var data_parsed = JSON.parse(data_decoded);
      this.stats = data_parsed.stats;
      this.cycle = data_parsed.cycle;
      this.data_ready = true;
    },
    onDownloadPdf: function onDownloadPdf() {
      var _this = this;

      this.$refs.ConfirmModal.open(null, function () {
        _this.onPDF();
      });
    },
    onPDF: function onPDF() {
      var _this2 = this;

      this.loading = this.$loading({
        lock: true,
        text: this.$t('cloud.title.report_generating'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.now = new Date(); // const container = document.createElement('div');

      var container = this.$refs.ReportContainer; // add option type to get the image version
      // if not provided the promise will return 
      // the canvas.

      html2canvas(container, {
        imageTimeout: 5000,
        useCORS: true
      }).then(function (canvas) {
        // container.appendChild(canvas)
        var img = canvas.toDataURL('image/png');
        var pdf = new JsPDF('l', 'mm', 'a4');
        pdf.addImage(img, 'JPEG', 5, 5, 287, 200);

        var filename = _this2.$utility.GetDateTimeStr("PeopleCounting-$yyyy$mm$dd-$HH$MM$ss.pdf", _this2.now);

        pdf.save(filename);

        _this2.loading.close();
      });
    },
    onDownloadJpg: function onDownloadJpg() {
      var _this3 = this;

      this.$refs.ConfirmModal.open(null, function () {
        _this3.onJpeg();
      });
    },
    onJpeg: function onJpeg() {
      var _this4 = this;

      this.loading = this.$loading({
        lock: true,
        text: this.$t('cloud.title.report_generating'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.now = new Date(); // const container = document.createElement('div');

      var container = this.$refs.ReportContainer; // add option type to get the image version
      // if not provided the promise will return 
      // the canvas.

      html2canvas(container, {
        imageTimeout: 5000,
        useCORS: true
      }).then(function (canvas) {
        // container.appendChild(canvas)
        var img = canvas.toDataURL('image/png');

        var filename = _this4.$utility.GetDateTimeStr("PeopleCounting-$yyyy$mm$dd-$HH$MM$ss.jpg", _this4.now);

        var link = document.createElement("a");
        link.setAttribute("href", img);
        link.setAttribute("download", filename);
        link.click();

        _this4.loading.close();
      });
    },
    onDownloadCsv: function onDownloadCsv() {
      var _this5 = this;

      this.$refs.ConfirmModal.open(null, function () {
        _this5.onCsv();
      });
    },
    onCsv: function onCsv() {
      var csv_array = [];
      var header_line = [];
      header_line.push(this.$t('cloud.title.log_at'));
      header_line.push(this.$t('cloud.title.people_count'));

      for (var i = 0; i < this.stats.people_count.length; i++) {
        var data_line = {};
        var time = this.$utility.GetDateTimeStr("$yyyy-$mm-$dd $HH:$MM", new Date(this.stats.people_count[i].x));
        data_line[this.$t('cloud.title.log_at')] = time;
        var count = this.stats.people_count[i].value;
        data_line[this.$t('cloud.title.people_count')] = count;
        csv_array.push(data_line);
      }

      this.$utility.DownloadCSV(csv_array, 'people_counting-$yyyy$mm$dd_$HH$MM$ss.csv');
    }
  }
};